import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tile from "../components/tile"

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
			<Tile text="General Information" path="/information-about-lupus" />
			<Tile text="Diagnostic Guidelines" path="/lupus-diagnostic-guidelines" />
			<Tile text="The Referral System" path="/the-referral-system" />
			<Tile text="Treatment Options" path="/treatment-options" />
			<Tile
				text="Monitoring Lupus"
				path="/lupus-lab-tests-diagnosing-and-monitoring"
			/>
			<Tile text="Useful Contacts" path="/useful-contacts" />
			<Tile text="Helpful Tools" path="/helpful-tools" />
			<Tile text="Latest Research" path="/latest-lupus-research" />
			<Tile text="About this webapp" path="/about" />
		</div>
	</Layout>
)

export default IndexPage
